import React, { useState, useEffect } from 'react'
import Slider, { createSliderWithTooltip, Range } from "rc-slider";
import axios from 'axios';
import VantageRewards from './VantageRewards';
import Carousel from '../Carousels/Carousel';
import HubspotForm from 'react-hubspot-form'
import VantageFit from './VantageFit';
import VantagePerks from './VantagePerks';
import VantagePulse from './VantagePulse';
import FullStack from './FullStack';

const NewPricingEn = () => {

    const [currentTab, setcurrentTab] = useState(20)
    const [currentDurationTab, setCurrentDurationTab] = useState(0)
    const [duration, setduration] = useState('monthly')
    const [width, setwidth] = useState(40)
    const [users, setusers] = useState(50)
    const [steps, setsteps] = useState(50)
    const [showDropdown, setshowDropdown] = useState(false)
    const [currentProduct, setcurrentProduct] = useState('Vantage Rewards')
    const [country, setCountry] = useState('')
    const [rewardsPrice, setRewardsPrice] = useState(500)
    const [serviceYearbook, setServiceYearbook] = useState(false)
    const [premiumSupport, setPremiumSupport] = useState(false)
    const [recognitionPulse, setRecognitionPulse] = useState(false)
    const [currency, setCurrency] = useState('₹')
    const [enterprisePrice, setEnterprisePrice] = useState('')
    const [max, setMax] = useState(2000)
    const [min, setMin] = useState(50)
    const [isAfrica, setisAfrica] = useState(false)

    const updatePricing = (e) => {
        setusers(e)

        if(country !== 'IN'){
            if(e < 101){
                setEnterprisePrice(e * 4)
            }
            else if(e > 100 && e < 251){
                setEnterprisePrice(e * 3)
            }     
        }
    }
    // Calculation to move slider thumb and prices when user edits the value directly instead of using the slider

    const changeUsers = (e) => {
        if(e.target.value < max + 1){
            setusers(e.target.value)
        }  
    }
    
    const handleTabSwitch = (e) => {
        if(e.target.id === 'fullstack'){
            setcurrentTab(0)
            setcurrentProduct('Full Stack')
        }
        else if(e.target.id === 'reward'){
            setcurrentTab(20)
            setcurrentProduct('Vantage Reward')
        }
        else if(e.target.id === 'perks'){
            setcurrentTab(40)
            setcurrentProduct('Vantage Perks')
        }
        else if(e.target.id === 'pulse'){
            setcurrentTab(60)
            setcurrentProduct('Vantage Pulse')
        }
        else if(e.target.id === 'fit'){
            setcurrentTab(80)
            setcurrentProduct('Vantage Fit')
        }
    }

    const handleCurrentDurationTab = (e) => {
        if(e.target.id === 'monthly'){
            setCurrentDurationTab(0)
            setwidth(40)
            setduration(e.target.id)
        }
        else if(e.target.id === 'yearly'){
            setCurrentDurationTab(50)
            setwidth(40)
            setduration(e.target.id)
        }
    }

    const handleDropdown = () => {
            setshowDropdown(!showDropdown)
    }

    const changeProduct = (e) => {
        setcurrentProduct(e.target.id)
        if(e.target.id === 'Full Stack') setcurrentTab(0)
        if(e.target.id === 'Vantage Rewards') setcurrentTab(20)
        if(e.target.id === 'Vantage Perks') setcurrentTab(40)
        if(e.target.id === 'Vantage Pulse') setcurrentTab(60)
        if(e.target.id === 'Vantage Fit') setcurrentTab(80)
        setshowDropdown(false)
    }

    const handleAddOns = (arg) => {
        if(arg == 'recognitionPulse'){
            setRecognitionPulse(!recognitionPulse)
        }
        else if(arg == 'premiumSupport'){
            setPremiumSupport(!premiumSupport)
        }
        else if(arg == 'serviceYearbook'){
            setServiceYearbook(!serviceYearbook)         
        }
    }

    const calculateRewards = () => {
        if(isAfrica){
            //african logic
            
            if(duration === 'yearly') {
                if(users < 101){
                    setEnterprisePrice(Math.floor(((90 * (users * 4) * 12)/100)/3))
                }
                else if(users > 100 && users < 251){
                    setEnterprisePrice(Math.floor(((90 * (users * 3) * 12)/100)/3))
                }
            }
            else if(duration === 'monthly'){
                if(users < 101){
                    setEnterprisePrice(Math.floor((users * 4)/3))
                }
                else if(users > 100 && users < 251){
                    setEnterprisePrice(Math.floor((users * 3)/3))
                }
            }
        }else{
            if (country === 'IN'){
                // india logic
                let basevalue = 10;
                let total = (basevalue * users) + (serviceYearbook===true ? ( users * 7) : 0) +  (premiumSupport === true ? ( users * 6) : 0) + (recognitionPulse== true  ? ( users * 6) : 0)
                duration === 'yearly' ? (setRewardsPrice(90*(total *12)/100)) : setRewardsPrice(total)
    
            }else{
                //rest of the world logc
                if(duration === 'yearly') {
                    setEnterprisePrice(90*(enterprisePrice * 12)/100)
                }
                else if(duration === 'monthly'){
                    if(users < 101){
                        setEnterprisePrice(users * 4)
                    }
                    else if(users > 100 && users < 251){
                        setEnterprisePrice(users * 3)
                    }
                }
            }
        }
    }


    useEffect(() => {
        calculateRewards()
    }, [users,serviceYearbook, premiumSupport,recognitionPulse, duration])

    const checkAfrica = (country_code) =>{
        let africanCountryList = ['AI','AL','AN','BA','BE','BT','BF','BR', 'SA', 'BU','BH','CR','CI','CV','CA','DH','DC','EG','ER','ET','GB','GA','GH','GC','GU','HO','IC','KY','LE','LI','LB','MD','MW','ML','MN','MU','MA','MB','ME','MR','MZ','NM','NG','NI','PY','CH','RD','RE','RG','RS','SY','RC','RI','RW','SE','SN','SL','SO','SA','SS','SU','SH','SW','TA','TO','TN','UG','UC','YA','ZA','ZM','ZI','ZU'];
        let result = !!africanCountryList.find(element => element == country_code);
        if(result===true){
            setisAfrica(true)
        }else{
            setisAfrica(false)
        }
        // console.log('result',result);

    }


    const getData = async () => {
        const res = await axios.get(`https://geolocation-db.com/json/`)
        setCountry(res.data.country_code)
        // setCountry('UK') // for development
        checkAfrica(res.data.country_code)
        // checkAfrica('UK') // for development
        
    }

   
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(country && country === 'IN'){
            setCurrency('₹')
            setMax(2000)
            setMin(50)
            setsteps(50)
            setusers(50)

        }else if(country === 'US' || country === 'UK' || country === 'AU'){
            setMax(250)
            setMin(10)
            setsteps(10)
            setusers(10)
            setEnterprisePrice(40)
            if(country === 'US'){
                setCurrency('$')
            }
            else if(country === 'UK'){
                setCurrency('£')
            }
            else if(country === 'AU'){
                setCurrency('AU$')
            }
        }else {
            if (isAfrica === true){
                setCurrency('$')
                setMax(250)
                setMin(10)
                setsteps(10)
                setusers(10)
                setEnterprisePrice(13)
            }
            else{
                if(country != ''){
                    setMax(250)
                    setMin(10)
                    setsteps(10)
                    setusers(10)
                    setCurrency('$')
                    setEnterprisePrice(40)
                }
            }
        }
    }, [country])

    return (
        <div className = "flex flex-col items-center w-full bg-gray-10">
            <div className = "w-full flex flex-col justify-center items-center relative bg-gradient-to-br from-purple-200 to-purple-1000 text-purple-100 border-b border-gray-150 py-6 md:py-12 px-5">
                <div className = "w-full md:w-6/12 2xl:w-5/12 font-bold text-3xl lg:text-4xl 2xl:text-5xl text-center flex justify-center items-center text-gray-800">
                    <h2 className = "text-purple-100">Choose a plan that best <span className = "text-orange">fits</span> your organization.</h2>
                </div>
                <div className = "hidden md:flex font-bold w-1/2 py-5 md:mt-10 xl:mt-16 mt-5 text-lg lg:text-xl xl:text-2xl text-center mb-0 lg:mb-5 justify-center items-center">
                    <h2>Select a Product</h2>
                </div>
                <div className = "hidden w-full lg:w-11/12 xl:w-10/12 2xl:w-7/12 lg:rounded-lg bg-white shadow-lg md:flex justify-center items-center px-3 py-1 lg:py-2 h-16 lg:h-20 border-b border-purple-150 absolute -bottom-8">
                    <div className = "grid grid-cols-5 justify-around items-center w-full xl:w-11/12 text-center text-lg lg:text-xl relative">
                        <h3 className = {currentTab === 0 ? "font-bold h-full flex justify-center items-center cursor-pointer z-10 tabActive" : "text-purple-200 font-bold h-full flex justify-center items-center cursor-pointer z-10 nonActive"} id = "fullstack" onClick = {handleTabSwitch}>Full Stack</h3>
                        <h3 className = {currentTab === 20 ? "font-bold h-full flex justify-center items-center cursor-pointer z-10 tabActive" : "text-purple-200 font-bold h-full flex justify-center items-center cursor-pointer z-10 nonActive"} id = "reward" onClick = {handleTabSwitch}>Vantage Rewards</h3>
                        <h3 className = {currentTab === 40 ? "font-bold h-full flex justify-center items-center cursor-pointer z-10 tabActive" : "text-purple-200 font-bold h-full flex justify-center items-center cursor-pointer z-10 nonActive"} id = "perks" onClick = {handleTabSwitch}>Vantage Perks</h3>
                        <h3 className = {currentTab === 60 ? "font-bold h-full flex justify-center items-center cursor-pointer z-10 tabActive" : "text-purple-200 font-bold h-full flex justify-center items-center cursor-pointer z-10 nonActive"} id = "pulse" onClick = {handleTabSwitch}>Vantage Pulse</h3>
                        <h3 className = {currentTab === 80 ? "font-bold h-full flex justify-center items-center cursor-pointer z-10 tabActive" : "text-purple-200 font-bold h-full flex justify-center items-center cursor-pointer z-10 nonActive"} id = "fit" onClick = {handleTabSwitch}>Vantage Fit</h3>
                        <div className = "pricingActive" style = {{ left : `${currentTab}%`, transition: `left 600ms cubic-bezier(0.68, -0.1, 0.265, 1.1)`}}></div>
                    </div>
                </div>
            </div> 

            <div className = {showDropdown ? "flex md:hidden w-full flex-col justify-center items-center mb-5 mt-10 expandDrop" : "flex flex-col md:hidden collapseDrop mb-0 md:mb-3 lg:mb-5 mt-4 md:mt-8 lg:mt-10"}>
                    <h2 className = "text-center my-3 font-bold">Select a Product</h2>
                    <div className = "py-2 px-3 rounded border border-purple-150 bg-white flex justify-between shadow">
                        <div className = "flex place-items-center w-5/6" onClick = {handleDropdown}>
                            <h2 className = "text-center font-bold text-center" style = {{width : '134px', width: '150px'}} >{currentProduct}</h2>
                        </div>
                        <div className = "w-1/6 flex place-items-center"><svg className="h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></div>
                    </div>

                    <ul className = {showDropdown ? "flex flex-col rounded border border-gray-150 mt-4 transform openDropdown" : "closeDropdown"} style = {{width : "190px"}}>
                        <li className = "py-3 px-6 text-center border-b border-gray-150 text-base bg-gray-100" id = "Full Stack" onClick = {changeProduct}>Full Stack</li>
                        <li className = "py-3 px-6 text-center border-b border-gray-150 text-base bg-gray-100" id = "Vantage Rewards" onClick = {changeProduct}>Vantage Rewards</li>
                        <li className = "py-3 px-6 text-center border-b border-gray-150 text-base bg-gray-100" id = "Vantage Perks" onClick = {changeProduct}>Vantage Perks</li>
                        <li className = "py-3 px-6 text-center border-b border-gray-150 text-base bg-gray-100" id = "Vantage Pulse" onClick = {changeProduct}>Vantage Pulse</li>
                        <li className = "py-3 px-6 text-center bg-gray-100 text-base rounded-b" id = "Vantage Fit" onClick = {changeProduct}>Vantage Fit</li>
                    </ul>           
            </div>

            <div className = {(currentTab === 20 || currentProduct === 'Vantage Rewards') ? `w-4/6 mt-2 md:mt-20 lg:mt-26 xl:mt-28 py-3 md:py-5 flex justify-center text-center` : `hidden`}>
                <h2 className ='mb-2 mt-6 md:mt-2 text-xl w-full xl:w-5/6 font-bold text-2xl lg:text-3xl'>How many users do you expect?</h2>
            </div>

            <div className = {(currentTab === 20 || currentProduct === 'Vantage Rewards') ? "flex flex-col md:flex-row w-11/12 md:w-5/6 lg:w-9/12 xl:w-4/6 2xl:w-3/6 mb-10 bg-white items-center xl:justify-center rounded-lg px-9 xl:px-9 py-5 shadow-lg border-b border-purple-150" : "hidden"}>
                <div className = "flex flex-col justify-center items-center md:items-start w-5/6">
                    <p className = 'mt-1 mb-8 md:mt-2 mb-0  pr-2 text-center md:text-left w-full lg:w-5/6'>Move the slider to estimate how many people you expect to participate.</p>   
                    <div className = "w-full mb-6 mt-1 md:w-5/6 md:mb-2 md:mt-2 flex justify-center md:justify-start">
                        <Slider min = {min} max = {max} step = {steps} onChange = {updatePricing} defaultValue= {users} value = {users} />

                    </div>
                    <div className = 'flex justify-between w-full md:w-5/6 font-semibold mt-2'>
                            <p className = 'text-sm'>{min}</p>
                            <p className = 'text-sm pr-2'>{max}</p>     
                    </div>
                    <p className = 'font-semibold md:mb-3 md:mt-3 text-md text-center md:text-left w-full xl:w-5/6'>Above {max} employees? <span><a href = '#'>Contact Sales</a></span></p>
                </div>

                <div className = "flex justify-center items-center rounded bg-purple-50 lg w-4/6 md:w-1/3 lg:w-1/5 xl:w-1/6  h-4/6">
                    <div className= "flex flex-col justify-center items-center w-full h-full p-3 rounded-lg font-semibold text-4xl border border-gray-150">
                        <input className = 'font-bold text-3xl pt-2 pb-2 text-center text-gray-200' type = "text" value = {users} onChange = {changeUsers}/>
                        <p className = "text-xl font-bold transform translate-y-2 text-gray-200">Employees</p>
                    </div>
                </div>
            </div>

            {/* <div className = "w-5/6 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 flex justify-around items-center rounded bg-purple-200 p-3 my-10 relative">
                <h3 className = {currentDurationTab == 0 ? "durationColor font-bold text-sm md:text-lg cursor-pointer z-10 w-full flex justify-center" : "text-white font-bold text-sm md:text-lg cursor-pointer z-10 w-full flex justify-center"} onClick = {handleCurrentDurationTab} id = "monthly">Pay Monthly</h3>
                <h3 className = {currentDurationTab == 50 ? "durationColor font-bold text-sm md:text-lg cursor-pointer z-10 w-full flex justify-center" : "text-white font-bold text-sm md:text-lg cursor-pointer z-10 w-full flex justify-center"} onClick = {handleCurrentDurationTab} id = "yearly">Pay Yearly &nbsp;<span className = {currentDurationTab == 46 ? "text-white purpleTab p-1 text-xs transition duration-500" : "text-orange purpleTab p-1 text-xs transition duration-500"}>10% Off</span></h3>
                <div className ="activeTab" style = {{ left : `${currentDurationTab}%`, transition: `all 600ms cubic-bezier(0.68, -0.2, 0.265, 1.25)`, width: `${width}%`}}></div>
            </div> */}

            <div className = {(currentTab === 20 || currentProduct === 'Vantage Rewards') ? `w-5/6 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 rounded-md bg-purple-500 shadow-lg flex justify-center items-center px-3 xl:px-0 py-1 lg:py-2 h-16 lg:h-16 border-b border-purple-150 mt-3 md:mt-6 lg:mt-20 mb-3 lg:mb-6` : `hidden`}>
                <div className = "grid grid-cols-2 justify-around items-center w-full xl:w-11/12 text-center text-lg lg:text-xl relative">
                    <h3 className = {currentDurationTab === 0 ? "font-bold h-full flex justify-center items-center cursor-pointer z-10 durationTabActive" : "text-purple-200 font-bold h-full flex justify-center items-center cursor-pointer z-10 durationTabNonActive"} id = "monthly" onClick = {handleCurrentDurationTab}>Monthly</h3>
                    <h3 className = {currentDurationTab === 50 ? "font-bold h-full flex justify-center items-center cursor-pointer z-10 durationTabActive" : "text-purple-200 font-bold h-full flex justify-center items-center cursor-pointer z-10 durationTabNonActive"} id = "yearly" onClick = {handleCurrentDurationTab}>Yearly&nbsp;<span className = {currentDurationTab == 50 ? "text-white purpleTab p-1 text-xs transition duration-500" : "text-orange purpleTab p-1 text-xs transition duration-500"}>10% Off</span></h3>
                    <div className = "durationActiveTab" style = {{ left : `${currentDurationTab}%`, transition: `left 600ms cubic-bezier(0.68, -0.2, 0.265, 1.25)`, width: '50%'}}></div>
                </div>
            </div>
  
                {(currentTab === 0 || currentProduct === 'Full Stack') && <FullStack />}
           
                {(currentTab === 20 || currentProduct === 'Vantage Rewards') && <VantageRewards
                country = {country}
                currency = {currency}
                enterprisePrice = {enterprisePrice}
                rewardsPrice = {rewardsPrice}
                handleAddOns = {handleAddOns}
                serviceYearbook = {serviceYearbook}
                premiumSupport = {premiumSupport}
                recognitionPulse = {recognitionPulse} />
                }

                {(currentTab === 40 || currentProduct === 'Vantage Perks') && <VantagePerks country = {country} />}
               
                {(currentTab === 60 || currentProduct === 'Vantage Pulse') && <VantagePulse />}

                {(currentTab === 80 || currentProduct === 'Vantage Fit') && <VantageFit />}
          
          <section className = "flex justify-center items-center bg-purple-20 w-full mt-6 lg:mt-12 border-t border-gray-150">
            <div class="testimonial-slider container text-center px-6 py-5 md:py-24 md:pb-10 my-5">
                    <Carousel />
            </div>
          </section>
         
          <section className = "w-full flex justify-center items-center py-12 pt-0 xl:pt-6 2xl:pt-12 bg-purple-20">
             <div className = "w-4/6 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6"><button className = "vc-ghost-btn">Check Client Success Stories</button></div>
          </section>
         
         <section className = "w-full bg-indigo-100 flex justify-center" >
            <div className = "w-11/12 my-16 py-10 px-5 grid grid-cols-1 lg:grid-cols-2 gap-3 justify-center item-start bg-indigo-100">
                <div className = "flex flex-col p-6 pt-0 justify-center">
                    <h2 className = "text-purple-100 font-bold text-5xl mb-5 text-center lg:text-left">Request a Quote.</h2>
                    <p className = "text-purple-100 text-center lg:text-left">Fill out this form to learn more about our pricing and get a custom quote as per your requirements.</p>
                </div>
                <div className ="w-full h-full flex justify-center items-center" id= "requestform">
                    <div className = "rounded-lg bg-white p-5 w-11/12">
                    <HubspotForm
                                portalId='6153052'
                                formId='d7303dc2-49a8-4385-a292-e7dca3f454e2'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                                class = "hbspt-form"
                            />
                    </div>
                </div>
            </div>
            </section>

            <section className = "w-full flex place-content-center px-6" >
                <div class="container max-w-full w-full lg:w-12/12 xl:w-8/12 mx-auto md:px-6 md:pb-20 md:pt-20 md:grid grid-cols-2 justify-around items-center">
                    <div class="text-center pb-5 md:pb-14 pt-10 my-1 md:pt-0 md:mr-5">
                        <h2 class="md:text-left text-2xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-gray-500">Got Questions?<br/> Read our FAQs.</h2>
                    </div>
                    <div class="career-faq p-2 md:p-5 w-full mb-10 md:ml-5">
                    <div class="px-1 py-5 toc-detail">
                        <div class="container max-w-full">
                            <div class="acc relative">
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading active">1. Can I convert Vantage Points into cash?</div>
                                    <div class="acc__panel" style={{display: "block", marginTop: "1.2rem", padding:"0.8rem"}}>
                                        Sorry, Vantage Points are not redeemable in cash.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">2. How will I get Vantage points?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                        You can earn Vantage points through shopping with us from various e-commerce sites. 
                                    </div>
                                </div>

                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">3. What is the validity period of my Vantage Points?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                        There is no validity period for the Vantage points earned through shopping into our platform. However, the validity period of the Vantage points received through rewards or awards from the organization is one year. Also, the reward points become invalid once you exit the organization.
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">4. How will I come to know that I have received Vantage Points?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                        Any credit or debit of Vantage Points in your account will be communicated via your registered email address. 
                                    </div>
                                </div>
                                <div class="acc__card py-3 rounded border-gray-600 border px-5 py-8">
                                    <div class="acc__title text-base haschildren toc-heading">5. Can I transfer my Vantage Points to my friends or family?</div>
                                    <div class="acc__panel" style={{display: "none;", marginTop: "1.2rem", padding:"0.8rem"}}>
                                        Vantage Points cannot be transferred or linked to any other account. Vantage Points can only be redeemed with the original account on which they were issued. 
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

        <section>

        </section>
        </div>
    )
}

export default NewPricingEn
