import React from 'react'
import './SkeletonPreloader.css'

const SkeletonPreloader = () => {
    return (
        <div className = "flex flex-col w-full justify-center items-center mb-20">
            <div className = "py-20 md:py-40 mb-0 md:mb-16 lg:mb-10 w-full preloadHeader"></div>
            <div className = "w-5/6 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 rounded-full bg-gray-150 flex justify-center items-center px-3 xl:px-0 py-1 lg:py-2 h-16 lg:h-16 my-8 md:my-10 lg:my-16 preloadHeader"></div>
            <div className = "rounded px-9 xl:px-10 py-28 md:py-40 xl:py-28 w-11/12 md:w-6/6 lg:w-11/12 xl:w-10/12 2xl:w-8/12 mb-5 mt-4 preloadHeader"></div>
            <div className = "rounded px-9 xl:px-10 py-40 md:py-64 xl:py-72 w-11/12 md:w-6/6 lg:w-11/12 xl:w-10/12 2xl:w-8/12 mb-2 md:mb-28 mt-2 preloadHeader"></div>
            <div className = "flex lg:hidden rounded px-9 py-40 md:py-64 w-11/12 md:w-6/6 mb-2 md:mb-10 mt-2 preloadHeader"></div>
        </div>
    )
}

export default SkeletonPreloader
