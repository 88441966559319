import React, { useState, useEffect } from "react"
import NewPricingEn from "../components/NewPricing/NewPricingEn"
import "../styles/global.css"
import Layout from "../components/layout"
// import axios from 'axios';
import useGeolocation from '../hooks/useGeoLocation'
import SkeletonPreloader from "../components/SkeletonPreloader/SkeletonPreloader"
import Helmet from "react-helmet"
import ModernPriceIN from "../components/ModerPriceFolder/ModernPriceIN"
import { useLanguages } from "../hooks/useLanguages"

const Plansandpricing = ({ location }) => {
  const [country, setCountry] = useState("")

  const [currentCountry] = useGeolocation()

  useEffect(() => {
    setCountry(currentCountry.country)
  }, [currentCountry])

  const [currLanguage] = useLanguages()

 return(
    <Layout location={location} pageLanguage={currLanguage}>
      <Helmet>
          <title>Plans and Pricing - Vantage Circle </title>

          <meta name="description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
          <meta property="twitter:title" content="Plans and Pricing | Vantage Circle" />
                  
          <meta property="twitter:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
                  
          <meta property="og:type" content="website"/>
                  
          <meta property="og:url" content="https://www.vantagecircle.com/plans-and-pricing/"/>

          <meta property="og:site_name" content="Vantage Circle"/>
          <meta property="og:title" content="Plans and Pricing | Vantage Circle" />

          <meta property="og:description" content="Compare and pick a plan that fits your requirement and foster a culture of recognition at work." />
          <link rel="canonical" href="https://www.vantagecircle.com/plans-and-pricing/"></link>
      </Helmet>
      <ModernPriceIN />
    </Layout>
 )
}

export default Plansandpricing
